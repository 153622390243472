<template>
  <div class="CustomFormRecordFormApproval">
    <form-panel
      v-bind="submitConfig"
      :form="form"
      :footerShow="isApprove"
    >
      <col2-detail>

        <col2-block>

          <el-form-item label="审批流程">
            <img class="work-flow-img" :src="workFlowImgSrc" />
          </el-form-item>

          <el-form-item label="审批历史">
            <vxe-table
              ref="vxeTable"
              :data="tableData"
              max-height="345"
              show-overflow
              border
            >
              <template v-for="(item, index) in tableHeaders">
                <vxe-table-column
                  :key="index"
                  :field="item.id"
                  :title="item.name"
                >
                  <template slot-scope="scope">
                    <span v-text="scope.row[item.id]" />
                  </template>
                </vxe-table-column>
              </template>
            </vxe-table>
          </el-form-item>

          <template v-if="isApprove">
            <el-form-item label="审批结果" :rules="[{ required: true, message: '请选择审批结果', trigger: 'change'}]" prop="approveStatus">
              <v-select v-model="form.approveStatus" :options="approveStatusOps" placeholder="请选择审批结果" :width="200" @change="approveStatusChange" />
            </el-form-item>

            <el-form-item label="审批意见">
              <v-textarea v-model="form.approveRemarks" placeholder="请输入审批意见" :maxlength="200" />
            </el-form-item>
          </template>

        </col2-block>

      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { getWorkFlowStatusURL, getWorkFlowImgURL, getApproveHistoryURL, workFlowApproveURL } from './api'
import { setApproveStatusOps } from './map'
import { Col2Detail, Col2Block } from 'components/bussiness'

import Vue from 'vue'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'

Vue.use(VXETable)

export default {
  name: 'CustomFormRecordFormApproval',
  components: {
    Col2Detail,
    Col2Block
  },
  data () {
    return {
      isApprove: false,
      workFlowImgSrc: undefined,
      approveStatusOps: setApproveStatusOps(),
      form: {
        processInstanceId: undefined,
        approveStatus: 1,
        approveRemarks: '已通过'
      },
      submitConfig: {
        submitMethod: 'POST',
        submitUrl: workFlowApproveURL
      },
      tableHeaders: [
        {
          id: 'approveUser',
          name: '审批人'
        },
        {
          id: 'endTime',
          name: '审批时间'
        },
        {
          id: 'approveStatus',
          name: '审批结果'
        },
        {
          id: 'approveRemarks',
          name: '审批意见'
        }
      ],
      tableData: []
    }
  },
  mounted () {
    const { processInstanceId, formName } = this.$route.query
    if (formName !== undefined) {
      const breadcrumb = `表单记录 —《${formName}》 > 审批`
      this.$setBreadcrumb(breadcrumb)
    } else {
      this.$setBreadcrumb('审批')
    }
    this.form.processInstanceId = processInstanceId
    this.getWorkFlowStatus()
    this.getWorkFlowImg()
    this.getApproveHistory()
  },
  methods: {
    async getWorkFlowStatus () {
      const url = `${getWorkFlowStatusURL}?procInstId=${this.form.processInstanceId}`
      const { status, data } = await this.$axios.post(url)
      if (status === 100) {
        this.isApprove = data
      }
    },
    async getWorkFlowImg () {
      const url = `${getWorkFlowImgURL}?instanceId=${this.form.processInstanceId}`
      const { status, data } = await this.$axios.post(url)
      if (status === 100) {
        this.workFlowImgSrc = `data:image/png;base64,${data}`
      }
    },
    async getApproveHistory () {
      const params = {
        procInstId: this.form.processInstanceId
      }
      const { status, data } = await this.$axios.get(getApproveHistoryURL, { params })
      if (status === 100) {
        if (Array.isArray(data) && data.length) {
          this.tableData = data
        }
      }
    },
    approveStatusChange (val) {
      if (val === 1) {
        this.form.approveRemarks = '已通过'
      } else if (val === 2) {
        this.form.approveRemarks = '已拒绝'
      } else if (val === 3) {
        this.form.approveRemarks = '已驳回，请修改'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.CustomFormRecordFormApproval {
  .work-flow-img {
    border: 1px dashed #999;
  }
}
</style>
