var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "CustomFormRecordFormApproval" },
    [
      _c(
        "form-panel",
        _vm._b(
          { attrs: { form: _vm.form, footerShow: _vm.isApprove } },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                [
                  _c("el-form-item", { attrs: { label: "审批流程" } }, [
                    _c("img", {
                      staticClass: "work-flow-img",
                      attrs: { src: _vm.workFlowImgSrc },
                    }),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审批历史" } },
                    [
                      _c(
                        "vxe-table",
                        {
                          ref: "vxeTable",
                          attrs: {
                            data: _vm.tableData,
                            "max-height": "345",
                            "show-overflow": "",
                            border: "",
                          },
                        },
                        [
                          _vm._l(_vm.tableHeaders, function (item, index) {
                            return [
                              _c("vxe-table-column", {
                                key: index,
                                attrs: { field: item.id, title: item.name },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", {
                                            domProps: {
                                              textContent: _vm._s(
                                                scope.row[item.id]
                                              ),
                                            },
                                          }),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ]
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _vm.isApprove
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "审批结果",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择审批结果",
                                  trigger: "change",
                                },
                              ],
                              prop: "approveStatus",
                            },
                          },
                          [
                            _c("v-select", {
                              attrs: {
                                options: _vm.approveStatusOps,
                                placeholder: "请选择审批结果",
                                width: 200,
                              },
                              on: { change: _vm.approveStatusChange },
                              model: {
                                value: _vm.form.approveStatus,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "approveStatus", $$v)
                                },
                                expression: "form.approveStatus",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "审批意见" } },
                          [
                            _c("v-textarea", {
                              attrs: {
                                placeholder: "请输入审批意见",
                                maxlength: 200,
                              },
                              model: {
                                value: _vm.form.approveRemarks,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "approveRemarks", $$v)
                                },
                                expression: "form.approveRemarks",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }