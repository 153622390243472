import { render, staticRenderFns } from "./CustomFormRecordFormApproval.vue?vue&type=template&id=4130f5dd&scoped=true"
import script from "./CustomFormRecordFormApproval.vue?vue&type=script&lang=js"
export * from "./CustomFormRecordFormApproval.vue?vue&type=script&lang=js"
import style0 from "./CustomFormRecordFormApproval.vue?vue&type=style&index=0&id=4130f5dd&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4130f5dd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/智慧物业-前端-新服务器/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4130f5dd')) {
      api.createRecord('4130f5dd', component.options)
    } else {
      api.reload('4130f5dd', component.options)
    }
    module.hot.accept("./CustomFormRecordFormApproval.vue?vue&type=template&id=4130f5dd&scoped=true", function () {
      api.rerender('4130f5dd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/common/customForm/CustomFormRecordFormApproval.vue"
export default component.exports